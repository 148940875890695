<template>
    <TopNavigation title="" hideSecondNavbar="true" />
    <div class="LegalityContainer">
        <div class="row">
            <div class="col-12 lblTitle text-center py-4">Legality Guide</div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">E-Signature Legality Summary</div>
                <div class="mb-3">Generally, electronic signatures have been recognized by law in Malaysia under the DSA and ECA.</div>
                <div class="mb-3">Electronic signatures are governed by the ECA. Section 6 of the ECA expressly states that any information in an electronic form shall not be denied its legal effect, validity, and enforceability.</div>
                <div>An enhanced electronic signature, called a digital signature, is a type of electronic signature that is governed by the DSA. Section 62(2) of the DSA specifically provides that a document signed with a digital signature in accordance with the DSA is as legally binding as a document signed with a handwritten signature, an affixed thumb-print or any other mark, and is deemed to be a legally binding signature.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">Types of Electronic Signature</div>
                <div class="mb-3">Electronic Signature: Section 5 of the ECA defines an electronic signature as "any letter, character, number, sound or any other symbol or any combination thereof created in an electronic form adopted by a person as a signature."</div>
                <div class="mb-3">Section 9 of ECA further provides that an electronic signature must:</div>
                <div class="mb-3">
                    <ul>
                        <li>Be attached or logically associated with the electronic message;</li>
                        <li>Adequately identify the person and adequately indicate the person's approval of the information to which the signature relates; and</li>
                        <li>Be as reliable as is appropriate given the purpose for which, and the circumstances in which, the signature is required.</li>
                    </ul>
                </div>
                <div class="mb-3">An electronic signature is "as reliable as is appropriate" if:</div>
                <div class="mb-3">
                    <ul>
                        <li>The means of creating the electronic signature is linked to and under the control of that person only;</li>
                        <li>Any alteration made to the electronic signature after the time of signing is detectable (tamper evident); and</li>
                        <li>Any alteration made to that document after the time of signing is detectable (tamper evident).</li>
                    </ul>
                </div>
                <div class="mb-3">Digital Signature: A digital signature is defined under section 2 of the DSA as "a transformation of a message using an asymmetric cryptosystem such that a person having the initial message and the signer's public key can accurately determine</div>
                <div class="mb-3 ps-3">
                    <div>1. whether the transformation was created using the private key that corresponds to the signer's public key; and</div>
                    <div>2. whether the message has been altered since the transformation was made".</div>
                </div>
                <div class="mb-3">Section 62(1) of the DSA states that where any law requires a signature of a person on a document, the requirement is fulfilled by a digital signature provided that the digital signature:</div>
                <div class="mb-3">
                    <ul>
                        <li>Must be verified by reference to the public key listed in a valid digital certificate issued by a licensed certification authority;</li>
                        <li>Must have been affixed by the signer with the intention of signing the message; and</li>
                        <li>The recipient must have no knowledge or notice that the signer has breached a duty as a subscriber or does not rightfully hold the private key used to affix the digital signature.</li>
                    </ul>
                </div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">Documents That May be Signed Electronically</div>
                <div class="mb-3">Section 2(1) of the ECA provides that electronic signatures are available to any commercial transaction conducted through electronic means including commercial transactions by the federal and state governments.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">Further Guidance</div>
                <div class="mb-3">Section 2 and the Schedule of the ECA expressly prohibits the use of electronic signatures for the following documents:</div>
                <div class="mb-3">
                    <ul>
                        <li>Power of Attorney</li>
                        <li>Trusts</li>
                        <li>Negotiable Instruments.</li>
                    </ul>
                </div>
                <div class="mb-3">Apart from the above which are listed under the ECA, the following documents must be notarized or attested by a commissioner for oaths/notary public and may not be able to be signed electronically:</div>
                <div class="mb-3">
                    <ul>
                        <li>Instruments Dealing with Real Property</li>
                        <li>Instruments of Transfers.</li>
                    </ul>
                </div>
                <div class="mb-3">Section 6 of ECA provides that an electronic message shall not be denied legal effect, validity, or enforceability provided that the information being referred to is accessible to the person against whom the referred information might be used. It should also satisfy the definition of electronic signature as listed above.</div>
                <div class="mb-3">Further, if the relevant digital signature criteria is met, then the digital signature is as legally binding as a document signed with a handwritten signature, an affixed thumbprint or any other mark, and such digital signature shall be deemed to be a legally binding signature.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">Case Law</div>
                <div class="mb-3">The following two cases are examples of where Malaysian courts have addressed the use of electronic signatures:</div>
                <div class="mb-3">
                    <ul>
                        <li>Yam Kong Seng & Anor v. Yee Weng Kai [2014] 4 MLJ 478; and</li>
                        <li>SS Precast Sdn Bhd v. Serba Dinamik Group Bhd & Ors [2020] MLJU 400.</li>
                    </ul>
                </div>
                <div class="mb-3 bg-light p-3">
                    <div>DISCLAIMER: The information on this site is for general information purposes only and is not intended to serve as legal advice. Laws governing the subject matter may change quickly, so DocuSign cannot guarantee that all the information on this site is current or correct. Should you have specific legal questions about any of the information on this site, you should consult with a licensed attorney in your area.</div>
                    <div>Last updated: September 7, 2021</div>
                </div>
            </div>

            <div class="col-12 py-4 text-center footerDesc" @click="$router.push({ path: '/' })">Back to Home</div>
        </div>

    </div>

    <div class="row">
        <div class="col-12 footer">
            <Footer />
        </div>
    </div>

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'LegalityGuide',
    components: { TopNavigation, Footer },
    setup () {

        return {}
    }
}
</script>

<style>
.footer {
    margin-top: 80px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    background-color: var(--navbar-light);
    border-top-color: var(--toolbar-hr);
}

.LegalityContainer {
    max-width: 1000px;
    margin: 60px auto 20px auto;
    border-radius: 10px;
}

.lblTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--section-title);
    font-family: "Inter-Medium", sans-serif;
}

.lblSubTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--section-title);
    font-family: "Inter-Medium", sans-serif;
    padding-bottom: 12px;
}

.lblContent {
    color: var(--section-title);
    margin: 0 0 40px 0;
    font-size: 17px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    text-align: justify;
    text-justify: inter-word;
}
</style>